import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  { label: '付款单号', prop: 'ppay_no', itemType: 'input', input: true, sortable: false, labelWidth: 140 },
  { label: '出运发票号', prop: 'tran_nos', itemType: 'input', input: true, sortable: false, labelWidth: 140 },
  { label: '采购发票号码', prop: 'pinv_actu_nos', itemType: 'input', input: true, sortable: false, labelWidth: 140, overflowTooltip: true },
  {
    label: '收票状态',
    prop: 'pinv_invstat',
    itemType: 'select',
    input: true,
    options: [
      { value: 0, label: '未收票' },
      { value: 1, label: '已收票' }
    ],
    sortable: false,
    widthAuto: false,
    formatter: val => formaInvstat(val)
  },
  /*  {
    label: '希望付款日',
    prop: 'ppay_ppdate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    formatter: val => getDateNoTime(val, true)
  },*/
  {
    label: '实际付款日',
    prop: 'ppay_rpdate',
    itemType: 'date',
    input: true,
    valueFormat: 'timestamp',
    sortable: false,
    widthAuto: false,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '付款金额', prop: 'ppay_total', itemType: 'input', input: false, sortable: false, labelWidth: 140 },
  { label: '总毛重', prop: 'prod_tgweight_total', itemType: 'input', input: false, sortable: false, widthAuto: false, labelWidth: 140 },
  { label: '总净重', prop: 'prod_tnweight_total', itemType: 'input', input: false, sortable: false, widthAuto: false, labelWidth: 140 },
  // { label: '公司抬头', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false, labelWidth: 140, overflowTooltip: true },
  {
    label: '公司抬头',
    prop: 'cpttNameList',
    itemType: 'select',
    options: [],
    input: true,
    sortable: false,
    collapseTags: true,
    filterable: true,
    labelWidth: 160,
    multiple: true,
    needOtherColumn: true,
    formatter: row => row.cptt_aname
  },
  { label: '外销抬头', prop: 'cptt_bname', itemType: 'input', input: false, sortable: false, labelWidth: 140, overflowTooltip: true },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: '120px',
    sortable: false,
    formatter: val => getDateNoTime(val, true)
  },
  store.state.status
];
export const syncDialogTableProperties = [
  { label: '付款单号', prop: 'ppay_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '出运发票号', prop: 'tran_nos', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '公司抬头', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '付款金额', prop: 'ppay_total', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
const formaInvstat = val => {
  if (val === 0) {
    return '未收票';
  } else if (val === 1) {
    return '已收票';
  } else {
    return '暂无';
  }
};
