<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexV">
        <el-button :disabled="!btn.delete" size="small" type="danger" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-date-picker
          v-model="dateRange"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          end-placeholder="结束日期"
          range-separator="至"
          size="small"
          start-placeholder="开始日期"
          type="daterange"
          value-format="timestamp"
        />
        <el-date-picker
          v-model="dateRange2"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          start-placeholder="实际付款开始日期"
          range-separator="至"
          end-placeholder="实际付款结束日期"
          type="daterange"
          value-format="timestamp"
        />
        <el-button icon="el-icon-search" size="small" type="primary" @click="getPpaysList()"> 查询</el-button>
        <el-button class="vd_export" icon="el-icon-refresh-right" size="small" type="info" @click="refresh()"> 刷新</el-button>
        <el-button plain size="small" type="primary" @click="confirmPinvInvstat">确认收票</el-button>
        <el-button class="vd_export" size="small" type="primary" @click="syncDialogVisible = true">同步老ERP</el-button>
        <div class="flexHV" style="margin-left: auto">
          <span>所有页付款金额合计:{{ sumPpayTotal }}</span>
          <span>当页付款金额合计:{{ objectArrayReduce(tableData, 'ppay_total') }}</span>
        </div>
      </div>
      <DynamicUTable
        ref="ppayMultiTable"
        :loadFlag="loadFlag"
        :columns="tableProperties"
        :tableData="tableData"
        :needSummation="true"
        :pageSize="50"
        :tableRowKey="'ppay_id'"
        :totalPage="totalPage"
        @getTableData="getPpaysList"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        :need-search="true"
        :needCheckBox="true"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </DynamicUTable>
      <el-dialog :visible.sync="syncDialogVisible" title="同步老ERP" top="0" width="70%">
        <div class="flexHV">
          <span style="margin-left: auto">注意:搜索为精确搜索，搜索过后才显示内容 同步数据将自动生效不可更改</span>
        </div>
        <div class="vg_mb_8">
          <el-button class="vd_export" size="small" type="success" @click="synchInput()"> 确定导入</el-button>
        </div>
        <DynamicUTable
          ref="dialogTable"
          :columns="dialogTableProperties"
          :need-check-box="true"
          :need-search="true"
          :needPagination="false"
          :table-data="dialogTableData"
          @getTableData="getSyncTableData"
          @selection-change="handleSelectionChangeRich"
        ></DynamicUTable>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { ppayAPI } from '@api/modules/ppay';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { syncDialogTableProperties, tableProperties } from '@/views/FinanceManagement/PpayManage/ppay';
import { getArrayIds, objectArrayReduce } from '@assets/js/arrayUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'PpayList',
  components: {
    DynamicUTable,
    SearchTable
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      syncDialogVisible: false,
      multiSelectionRich: [],
      dialogTableData: [],
      dialogTableProperties: syncDialogTableProperties,
      sumPpayTotal: ''
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/ppay_edit') {
        this.initData();
      }
    }
  },
  methods: {
    objectArrayReduce,
    initData() {
      this.getPpaysList();
    },
    async getPpaysList() {
      await (this.loadFlag = true);
      let searchForm = cloneDeep(this.$refs.ppayMultiTable.searchForm);
      let [startTime, endTime] = this.dateRange || [];
      let [startTime1, endTime1] = this.dateRange2 || [];
      searchForm.startTime = startTime ? startTime / 1000 : null;
      searchForm.endTime = endTime ? endTime / 1000 : null;
      searchForm.startTime1 = startTime1 ? startTime1 / 1000 : null;
      searchForm.endTime1 = endTime1 ? endTime1 / 1000 : null;
      searchForm.ppay_rpdate = searchForm.ppay_rpdate ? searchForm.ppay_rpdate / 1000 : null;
      searchForm.cpttNameList = searchForm.cpttNameList?.toString();
      await getNoCatch(ppayAPI.getPpays, searchForm).then(({ data }) => {
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.sumPpayTotal = data.sumPpayTotal;
        this.btn = data.btn;
        let temp = false;
        for (const key in data) {
          if (key.includes('sum_')) {
            this.tableProperties.find(({ prop }) => prop === key.substring(4))[key] = data[key];
            temp = true;
          }
        }
        this.tableProperties.find(({ prop }) => prop === 'cpttNameList').options = data.cpttNameList;
        this.loadFlag = false;
      });
    },
    synchInput() {
      this.$confirm('确定同步？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          ppayAPI.addPPayByRichMax(this.multiSelectionRich).then(() => {
            this.$message.success('同步成功!');
            this.syncDialogVisible = false;
            // this.$refs.ppayMultiTable.searchForm.ppay_no = row.ppay_no;
            // this.getPpaysList();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    handleSelectionChangeRich(val) {
      this.multiSelectionRich = val;
    },
    // 确认收货
    confirmPinvInvstat() {
      if (this.multiSelection.length === 0) return this.$message.info('至少选择一条数据');
      postNoCatch(ppayAPI.editPpayPinvInvstat, { listPPayId: getArrayIds(this.multiSelection, 'ppay_id') }).then(({ data }) => {
        this.$message.success('已确认收票!');
        this.initData();
      });
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/ppay_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 137,
            form_id: row.ppay_id
          })
        )
      });
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.ppayMultiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.ppay_id);
          });
          postNoCatch(ppayAPI.deletePpayByIds, { ppay_id_list: ids }).then(() => {
            this.$message.success('删除成功!');
            this.refresh();
          });
        })
        .catch(() => this.$message.info('取消删除!'));
    },
    refresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.ppayMultiTable.resetFields();
      this.getPpaysList();
    },
    getSyncTableData() {
      let deep = cloneDeep(this.$refs.dialogTable.searchForm);
      delete deep.page_no;
      if (Object.values(deep).every(value => !value)) return;
      ppayAPI.getPPayListByRichMax(this.$refs.dialogTable.searchForm).then(({ data }) => {
        this.dialogTableData = data.list;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
